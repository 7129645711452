<template>
  <div class="article-layout-sidebar-item">
    <div class="sidebar-label mb-1">
      {{ $texts('chapter', 'Kapitel') }}
    </div>
    <div class="input-group">
      <select v-model="chapter" class="form-select mb-2 mr-sm-2 mb-sm-0">
        <option
          v-for="(item, key) in chapterOptions"
          :key="key"
          :value="item.value"
        >
          {{ item.text }}
        </option>
      </select>
      <div class="input-group-append">
        <component
          :is="prevChapter.is"
          v-bind="prevChapter.attrs"
          class="btn btn-primary"
        >
          <LeftIcon />
        </component>
      </div>
      <div class="input-group-append">
        <component
          :is="nextChapter.is"
          v-bind="nextChapter.attrs"
          class="btn btn-primary"
        >
          <RightIcon />
        </component>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import RightIcon from '~icons/bi/chevron-right'
import LeftIcon from '~icons/bi/chevron-left'

const props = defineProps({
  chapters: {
    type: Object as PropType<number[]>,
    required: true,
  },
})

const { $texts } = useNuxtApp()
const route = useRoute()
const router = useRouter()

const chapter = computed({
  get: () => {
    return parseInt('' + route.params.chapter) || 1
  },
  set: (chapter: number) => {
    if (chapter) {
      router.push({
        name: 'library-bible-book-chapter',
        params: {
          bible: route.params.bible,
          book: route.params.book,
          chapter: chapter.toString(),
        },
      })
    } else {
      router.push({
        name: 'library-bible-book',
        params: {
          bible: route.params.bible,
          book: route.params.book,
        },
      })
    }
  },
})

const chapterOptions = computed(() => {
  return props.chapters.map((chapter) => ({
    value: chapter,
    text: chapter.toString(),
  }))
})

const prevChapter = computed(() => {
  return getChapterLink(-1)
})

const nextChapter = computed(() => {
  return getChapterLink(1)
})

const getChapterLink = (move: number) => {
  const nextChapter = chapter.value + move
  const entry = props.chapters.find(function (entry) {
    return entry == nextChapter
  })
  if (chapter.value && entry) {
    return {
      is: resolveComponent('NuxtLink'),
      attrs: {
        to: {
          name: 'library-bible-book-chapter',
          params: {
            ...route.params,
            chapter: entry,
          },
        },
      },
    }
  }

  return {
    is: 'button',
    attrs: {
      disabled: true,
    },
  }
}
</script>
