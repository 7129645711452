<template>
  <div v-if="result">
    <div v-if="result.collection_name" class="text-muted small">
      {{ result.collection_name }}
    </div>
    <DrupalLink v-if="isBook" :to="result.url" target="_blank">
      {{ result.book_title_merged }}: {{ result.title }}
    </DrupalLink>
    <DrupalLink v-else :to="result.url">
      {{ result.title }}
    </DrupalLink>
    <div v-if="passages" class="passage small">
      <em>{{ passages }}</em>
    </div>
    <hr />
  </div>
</template>

<script setup lang="ts">
import type { RefItemFragment } from '#graphql-operations'
// Props
const props = defineProps({
  result: Object as PropType<RefItemFragment>,
})

const { $texts } = useNuxtApp()

// Computed
const isBook = computed(() => {
  switch (props.result?.bundle) {
    case 'chapter':
    case 'subchapter':
      return true
  }
  return false
})

const passages = computed(() => {
  if (props.result?.bible_passage) {
    const separator = $texts('verse_separator', '; ')
    return props.result?.bible_passage.join(separator)
  }
  return ''
})
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables';
.explanations {
  margin-top: 0.5rem;

  hr {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .last {
    hr {
      display: none;
    }
  }

  a {
    display: block;
    font-size: $font-size-base;
  }
  .passage {
    color: $black;
  }
}
</style>
