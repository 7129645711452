<template>
  <div class="article-layout-sidebar-item">
    <div class="sidebar-label mb-1">{{ $texts('book', 'Buch') }}</div>
    <select v-model="book" class="form-select mb-2 mr-sm-2 mb-sm-0">
      <option v-for="(item, key) in bookOptions" :key="key" :value="item.value">
        {{ item.text }}
      </option>
    </select>
  </div>
</template>

<script setup lang="ts">
import type { BibleBookFragment } from '#graphql-operations'

const props = defineProps({
  books: {
    type: Object as PropType<BibleBookFragment[]>,
    required: true,
  },
})

const { $texts } = useNuxtApp()
const route = useRoute()
const router = useRouter()

const book = computed({
  get: () => {
    return '' + route.params.book
  },
  set: (book: string) => {
    if (book) {
      router.push({
        name: 'library-bible-book',
        params: {
          bible: route.params.bible,
          book,
        },
      })
    } else {
      router.push({
        name: 'library-bible',
        params: {
          bible: route.params.bible,
        },
      })
    }
  },
})

const bookOptions = computed(() => {
  const books = props.books.map((book) => ({
    value: book.slug,
    text: book.longName,
  }))

  books.unshift({
    value: '',
    text: $texts('please_choose', 'Bitte wählen'),
  })
  return books
})
</script>
