<template>
  <div class="article-layout-sidebar-item">
    <div class="sidebar-label mb-1">{{ $texts('bible', 'Bibel') }}</div>
    <select v-model="bible" class="form-select mb-2 mr-sm-2 mb-sm-0">
      <option
        v-for="(item, key) in bibleOptions"
        :key="key"
        :value="item.value"
      >
        {{ item.text }}
      </option>
    </select>
  </div>
</template>

<script setup lang="ts">
import type { BiblesFragment } from '#graphql-operations'

const props = defineProps({
  bibles: {
    type: Object as PropType<BiblesFragment[]>,
    required: true,
  },
})

const { $texts } = useNuxtApp()
const route = useRoute()
const router = useRouter()

const bible = computed<string>({
  get: () => {
    if (route.params.bible) {
      return '' + route.params.bible
    }
    return ''
  },
  set: (bible: string) => {
    if (bible) {
      router.push({
        name: 'library-bible',
        params: {
          bible,
        },
      })
    } else {
      router.push({
        name: 'library-bible-parent',
      })
    }
  },
})

const bibleOptions = computed(() => {
  const bibles = props.bibles.map((bible) => ({
    value: bible.code,
    text: bible.title,
  }))

  bibles.unshift({
    value: '',
    text: $texts('please_choose', 'Bitte wählen'),
  })
  return bibles
})
</script>
