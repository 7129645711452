<template>
  <div id="top">
    <article-layout :title="hero.title" :lead="hero.lead">
      <template v-if="bibleData" #before>
        <bible-navigation-bible
          v-if="bibleData.bibles"
          :bibles="bibleData.bibles"
        />
        <bible-navigation-book v-if="bible" :books="bibleData.books" />
        <bible-navigation-chapter
          v-if="bible && book"
          :chapters="bibleData.chapters"
        />
        <bible-references
          v-if="bible && book && chapter"
          :key="bookId + '_' + chapter"
          :book="bookId"
          :chapter="chapter"
        />
      </template>
      <slot />
    </article-layout>
  </div>
</template>

<script setup lang="ts">
// Unfortunately, I have to use layouts because with nested routes, the useAsyncData
// refresh on route change does not work.
// Maybe one day this can be moved to bible/index.vue.
// https://github.com/nuxt/nuxt/issues/22955
const nuxtRoute = useRoute()
const router = useRouter()
const language = useCurrentLanguage()

const { $texts } = useNuxtApp()

// Get the dropdown data
const { data: bibleData } = await useAsyncData(
  'bible' + nuxtRoute.fullPath,
  async () => {
    const variables = {
      bible: '' + nuxtRoute.params.bible,
      book: '' + nuxtRoute.params.book,
      language: language.value,
    }
    return useGraphqlQuery('bibleBooks', variables).then((v) => {
      return {
        bibles: v.data.bibles || [],
        books: v.data.bible?.books || [],
        chapters: v.data.bookChapters?.book?.chapters || [],
      }
    })
  },
  {
    watch: [() => nuxtRoute.params],
  },
)

// This is the only way. UseState() does not work.
provide('bibleData', bibleData)

const bible = computed(() => {
  return (
    bibleData.value?.bibles.find((v) => v.code === nuxtRoute.params.bible) ||
    null
  )
})
const book = computed(() => {
  if (nuxtRoute.params.book) {
    return (
      bibleData.value?.books?.find((v) => v.slug === nuxtRoute.params.book) ||
      null
    )
  }
  return null
})

const bookId = computed(() => {
  return book?.value?.bookNumber || null
})

const chapter = computed(() => {
  return parseInt('' + nuxtRoute.params.chapter) || null
})

const title = computed(() => {
  if (bible?.value) {
    return bible?.value?.title
  }
  return $texts('choose_bible', 'Bibel wählen')
})
const lead = computed(() => {
  const parts: string[] = []
  if (book.value) {
    parts.push(book.value.longName || '')
  }
  if (chapter.value) {
    parts.push($texts('chapter', 'Kapitel') + ' ' + chapter.value)
  }

  return parts.filter(Boolean).join(' » ')
})

const hero = computed(() => {
  return {
    title: book.value ? lead.value : title.value,
    lead: book.value ? title.value : lead.value,
  }
})

/**
 * Build the custom breadcrumb for the bible pages.
 */
const breadcrumb = computed(() => {
  const breadcrumb = [
    {
      title: $texts('homepage', 'Startseite'),
      url: {
        path: '/' + language.value,
      },
    },
    {
      title: $texts('bible', 'Bibel'),
      url: {
        path: router.resolve({
          name: 'library-bible-parent',
        }).fullPath,
      },
    },
  ]

  if (bible.value) {
    breadcrumb.push({
      title: bible?.value?.title || '',
      url: {
        path: router.resolve({
          name: 'library-bible',
          params: {
            bible: nuxtRoute.params.bible,
          },
        }).fullPath,
      },
    })

    if (book.value) {
      breadcrumb.push({
        title: book?.value?.longName || '',
        url: {
          path: router.resolve({
            name: 'library-bible-book',
            params: {
              bible: nuxtRoute.params.bible,
              book: nuxtRoute.params.book,
            },
          }).fullPath,
        },
      })

      if (chapter.value) {
        breadcrumb.push({
          title: $texts('chapter', 'Kapitel') + ' ' + chapter.value,
          url: {
            path: router.resolve({
              name: 'library-bible-book-chapter',
              params: {
                bible: nuxtRoute.params.bible,
                book: nuxtRoute.params.book,
                chapter: nuxtRoute.params.chapter,
              },
            }).fullPath,
          },
        })
      }
    }
  }

  return breadcrumb
})

setBreadcrumbLinks(breadcrumb.value)

useHead({
  title: bible?.value?.title,
})
</script>
