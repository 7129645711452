<template>
  <div v-if="data" class="article-layout-sidebar-item">
    <div v-if="data.collections.length" class="sidebar-label">
      {{ $texts('collections', 'Sammlungen') }}
    </div>
    <div v-if="data.collections.length" class="explanations">
      <div v-for="(result, key) in data.collections" :key="key">
        <BibleReferencesItem
          :result="result"
          :class="{ last: key === data.collections.length - 1 }"
        ></BibleReferencesItem>
      </div>
    </div>
    <div v-if="data.books.length" class="sidebar-label">
      {{ $texts('books_articles', 'Artikel und Bücher') }}
    </div>
    <div v-if="data.books.length" class="explanations">
      <div v-for="(result, key) in data.books" :key="key">
        <BibleReferencesItem
          :result="result"
          :class="{ last: key === data.books.length - 1 }"
        ></BibleReferencesItem>
      </div>
    </div>
    <div
      v-else-if="
        !pending && data.collections.length === 0 && data.books.length == 0
      "
      class="small"
    >
      {{ $texts('no_comments_found', 'Keine Kommentare gefunden') }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { pad } from '~/helpers'

const perPage = ref(100)

const { $texts } = useNuxtApp()

// Props
const props = defineProps({
  book: {
    type: null as unknown as PropType<number | null>,
    default: null,
    required: false,
  },
  chapter: {
    type: Number,
    default: 0,
  },
})

// Get the data.
const { data, pending } = await useAsyncData(async () => {
  const bookFilter = parseInt(props.book + pad(props.chapter, 3))
  const variables = {
    filter: '' + bookFilter,
    filter_start: bookFilter + '000',
    filter_end: bookFilter + '999',
    limit: perPage.value,
  }
  return useGraphqlQuery('listBible', variables).then((v) => {
    return {
      collections:
        v.data.results?.documents?.filter(
          (result) => result.collection_name !== '',
        ) || [],
      books:
        v.data.results?.documents?.filter(
          (result) => result.collection_name === '',
        ) || [],
    }
  })
})
</script>

<style lang="scss" scoped>
div {
  .sidebar-label:not(:first-child) {
    margin-top: 2rem;
  }
}
</style>
